import axios from 'axios';
import React, { useEffect, useState } from 'react'
import '../../Track.css';
import Modal from 'react-modal';
import { Rating } from 'react-simple-star-rating'
import full_circle from '../../images/full_circle.png'
import one_circle from '../../images/one_circle.png'
import two_circle from '../../images/two_circle.png'
import three_circle from '../../images/three_circle.png'
import { Link } from 'react-router-dom';
import banner from '../../images/banners/login.jpg'
import { Helmet } from 'react-helmet';
export default function TrackAWB() {
    const INTEGRA_URL = process.env.REACT_APP_INTEGRA_URL;
    const [rating1, setRating] = useState({
        awbno:'',
        rate:'',
        comment:''
    }) // initial rating value
   
    const [comment, setcomment] = useState('')
  
    function handleRating(rate){
        setRating({...rating1,awbno:awb,rate:rate})
       
    }
    const url=window.location.href
    const awbno = url.split("/").pop();
   const [awb, setawb] = useState(awbno);
    const [trackhist, settrackhist] = useState(false)
    const [trackdetails, settrackdetails] = useState([])
    let finalsts=[];
    const [showModal, setShowModal] = useState(false);
   
    const [circleimage, setcircleimage] = useState('https://www.timexpress.ae/wp-content/themes/timeexpress-child/images/timer/full_circle.png')
    //const API_PATH = 'https://timexdemo.000webhostapp.com/php/tracking.php';
    const API_PATH = `${INTEGRA_URL}tracking.php`;


    const [lateststs, setlateststs] = useState()
    useEffect(() => {
        axios({
            method: 'post',
            url: `${API_PATH}`,
            data: awb
        })
            .then((res) => {
              //  console.log(res.data)
                if (res.data.description = "Success" )  {
                    settrackhist(true)
                  settrackdetails(res.data.trackList.reverse());
                  if(trackdetails){

                    let count=res.data.trackList.length
                    let finalsts=res.data.trackList[0]
                    console.log(finalsts.StatusCode)
                    setlateststs(finalsts.Status)
                    if(finalsts.StatusCode=="POD"){
                        setcircleimage(full_circle)
                    }else if(finalsts.StatusCode=="C"){
                        setcircleimage(two_circle)
                    }else if(finalsts.StatusCode=="N"){
                        setcircleimage(one_circle) 
                    
                    }else{
                        setcircleimage(three_circle)
                    }
                  }
                 
               
                }

            })
            .catch(error => console.log(error.message));
    },[])



    function getTracking(event) {
        event.preventDefault();

        axios({
            method: 'post',
            url: `${API_PATH}`,
            data: awb
        })
            .then((res) => {
            //  console.log(res.data)
                if (res.data.description = "Success") {
                    settrackhist(true)
                  settrackdetails(res.data.trackList.reverse());
                  if(trackdetails){

                    let count=res.data.trackList.length
                    let finalsts=res.data.trackList[0]
                    setlateststs(finalsts.Status)
            //         console.log('final')
            //    console.log(finalsts.StatusCode)
            if(finalsts.StatusCode=="POD"){
                console.log('full')
                setcircleimage(full_circle)
            }else if(finalsts.StatusCode=="C"){
                console.log('two')
                setcircleimage(two_circle)
            }else if(finalsts.StatusCode=="N"){
                console.log('one')
                setcircleimage(one_circle) 
            
            }else{
                console.log('three')
                setcircleimage(three_circle)
            }
                  }
               
                }

            })
            .catch(error => console.log(error.message));
    }

    const [modalIsOpen,setModalIsOpen] = useState(false);

    const setModalIsOpenToTrue =()=>{
        setModalIsOpen(true)
    }

    const setModalIsOpenToFalse =()=>{
        setModalIsOpen(false)
    }
    const API_PATH_RATE=`${INTEGRA_URL}send_rating.php`;

    function sendRating(){
        axios({
            method: 'post',
            url: `${API_PATH_RATE}`,
            data: rating1
        })
        .then((res)=>console.log(res.data))
        .catch((err)=>console.log(err))

    }
    function gettrackdate(trackdate){
        let myDate = new Date(trackdate);
     let noTime=` ${myDate.getMonth()+1}/${myDate.getDate()}/${myDate.getFullYear()}`
     return noTime
    }
   
    return (
        <div className="track">
            <Helmet>
        <title>Time Express | Track</title>
      </Helmet>
<Modal isOpen={modalIsOpen} ariaHideApp={false}>
    <button onClick={setModalIsOpenToFalse}>x</button>
    <>
           <div className="container">
               <div className="row ratepop">
                   <h3>Rate your delivery experience</h3>
                   <h2>today</h2>
                   <p>How was the service you received for your shipment?</p>
                   <Rating onClick={handleRating} ratingValue={rating1.rate} />
                   <textarea className=" form-control" value={rating1.comment} onChange={(e)=>setRating({...rating1,comment:e.target.value})} rows="4" cols="20" id="" placeholder="your comment" / >
                   <div className="remind_later"> 
                   {/* <a href="javascript:void(0)" id="remindLater" className="remind">Remind me later</a>  */}
                   <a  id="getRating" className="send_btn" onClick={sendRating}>Send</a>
                   </div>
               </div>
           </div>
         
            </>
</Modal>


            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link rel="preconnect" href="https://fonts.gstatic.com" />
            <link href="https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,500;0,600;0,700;1,500&display=swap" rel="stylesheet" />
            <section className="your_business_page fw" style={{ backgroundImage:  `url(${banner})`, backgroundAttachment: 'fixed', backgroundSize: 'cover' }}>
           <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-sm-12 your_business text-center track-parcel">
                            <div className="business_content">
                            <h1 className="mainhead">Track Your Parcel</h1>
                            </div>
                            <h3 style={{ textAlign: "center" }}>Got a parcel to track?</h3>
                            <div className="formtrack">
                                <form name="tracking" method="get" id="tracking_track" action="#">
                                    <ul className="business_region">
                                        <li>
                                            <input required id="TrackingCode" onChange={(e) => setawb(e.target.value)} className="form-control" name="awbno" placeholder="Tracking number" type="text" />
                                        </li>

                                        <li><button className="btn form-control trackbtn noradius" type="submit" onClick={e => getTracking(e)} style={{ minWidth:" 100%", borderRadius:"0px !important"}}>Track</button></li>
                                    </ul>
                                </form>
                            </div>

                            {/* <a href="https://www.timexpress.ae/collect-or-drop-off-parcel/" className="masthead__findtrack">+ COLLECT YOUR PARCEL</a> */}
                        </div>
                    </div>
                </div>
            </section>

            <div className="pages_content">
                <div className="container">

                {
                        trackhist &&  (
                            
                    <div className="row">
                    
                        <div id="primary" className="content-area" style={{ float: 'left', width: '100%' }}>
                            <div className="delivery-tracking">
                                <div className="time-sutes">
                                    <h3>Delivery Tracking</h3>
                                    <p>Keep track of your parcel at all times.</p>
                                    <div className="box-time"><span>{awb}</span>
                                    </div>
                                </div>
                                <div className="circle-sec col-md-12 col-sm-12"><div className="circle_status">
                                    <div className="circle-img">
                                        <noscript>&lt;<img src="" />
                                        </noscript>
                                        <img className=" lazyloaded" src={circleimage} data-src={circleimage}/>
                                        </div>
                                        <div className="status-driver-content"><div className="status-driver"><p>Status</p>
                                        <span>{lateststs}</span></div></div></div></div><div className="parcel-table">
                                    <div className="Parcel-box">
                                        <h3>Parcel History</h3>
                                        <p>Historical status information for Parcel Number {awb}</p>
                                        <div className="table-responsive">
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <th>Received By/Remark</th>
                                                        <th width={125}>Date</th>
                                                        <th width={250}>Time</th>
                                                        {/* <th width={285}>Description</th> */}
                                                    </tr>
                                                    {
                                                        trackdetails.map((track,i)=>( 

                                                            i==0?(
                                                                <tr key={i} style={{fontWeight:'bold'}}>
                                                                <td> {track.Status} </td>
                                                                <td>{gettrackdate(track.TransDate)}</td>
                                                                <td> {track.TransTime}</td>
                                                                {/* <td> {track.StatusCode}</td> */}
                                                            </tr>
                                                            ):(
                                                                <tr key={i} >
                                                                <td> {track.Status} </td>
                                                                <td>{gettrackdate(track.TransDate)}</td>
                                                                <td> {track.TransTime}</td>
                                                                {/* <td> {track.StatusCode}</td> */}
                                                            </tr>
                                                            )
                                                        ))
                                                    }
                                                    

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="btn-rate">
                                        <a data-fancybox data-src="#hidden-content" onClick={setModalIsOpenToTrue} className="rate-delivery">Rate My Delivery</a>
                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                        )
}



<div id="portal"></div>   

                    <div className="track-hedding"><h1>ABOUT TRACKING</h1></div>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="needs_box">
                                <div className="business_img" >
                                    <div className="business_needs" >
                                        <h3 className="block__title">Take Advantage of cheap freight</h3>
                                        <p></p>
                                        <p>Our courier and air freight services make it easier than ever for businesses, individuals and families to move goods to and from Dubai</p>
                                        <p></p>
                                    </div>
                                    <div className="needs_but"> <Link className="btn btn--black" to="/track/take-advantage-of-cheap-freight-from-uae/">Find out more</Link></div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="needs_box">
                                <div className="business_img" >
                                    <div className="business_needs" >
                                        <h3 className="block__title">Find out where your parcel is</h3>
                                        <p></p>
                                        <p>Simply enter your parcel reference number above and hit track to find out where your parcel is.</p>
                                        <p></p>
                                    </div>
                                    <div className="needs_but"> <Link className="btn btn--black" to="/track/find-out-where-your-parcel-is/">Find out more</Link></div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="needs_box">
                                <div className="business_img" >
                                    <div className="business_needs" >
                                        <h3 className="block__title">Re-Arrange a Delivery</h3>
                                        <p></p>
                                        <p>Get the Parcel You Missed Redelivered If we missed you when we tried to deliver your parcel, you can schedule redelivery</p>
                                        <p></p>
                                    </div>
                                    <div className="needs_but"> <Link className="btn btn--black" to="/track/re-arrange-a-delivery/">Find out more</Link></div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="row" style={{ marginTop: "3%" }}>
                        <div className="col-md-4">
                            <div className="needs_box">
                                <div className="business_img" >
                                    <div className="business_needs" >
                                        <h3 className="block__title">Schedule Your Pick-Up and Delivery Time</h3>
                                        <p></p>
                                        <p>Depending on the service that has been purchased by the sender and if you are having a busy schedule and want the convenience of selecting a specific</p>
                                        <p></p>
                                    </div>
                                    <div className="needs_but"> <Link className="btn btn--black" to="/track/schedule-your-pick-up-and-delivery-time/">Find out more</Link></div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="needs_box">
                                <div className="business_img" >
                                    <div className="business_needs" >
                                        <h3 className="block__title">Sunday Delivery</h3>
                                        <p></p>
                                        <p>You can also have your parcels delivered on a Sunday with Time Express. If you wish to have your parcel delivered on a Sunday.</p>
                                        <p></p>
                                    </div>
                                    <div className="needs_but"> <Link className="btn btn--black" to="/track/sunday-delivery">Find out more</Link></div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="needs_box">
                                <div className="business_img" >
                                    <div className="business_needs" >
                                        <h3 className="block__title">Same Day Delivery</h3>
                                        <p></p>
                                        <p>Do You Offer a Same Day Courier Collection Service?</p>

                                        <p>We certainly do, but you’ve got to be snappy about it!</p>

                                        <p>Are you an E-commerce company Or simply a business or consumer looking to deliver an Online orders on the same day?</p>
                                        <p></p>
                                    </div>
                                    <div className="needs_but"> <Link className="btn btn--black" to="/track/same-day-delivery/">Find out more</Link></div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="row" style={{ marginTop: "3%" }}>
                        <div className="col-md-2">&nbsp;</div>
                        <div className="col-md-4">
                            <div className="needs_box">
                                <div className="business_img" >
                                    <div className="business_needs" >
                                        <h3 className="block__title">Next Day Delivery</h3>
                                        <p></p>
                                        <p>Our next-day service delivery ensures that your products or services are delivered to your customers within one business day of their order placement. This expedited delivery option provides your customers with a fast and efficient solution, enhancing their overall satisfaction and loyalty to your brand.</p>
                                        <p></p>
                                    </div>
                                    <div className="needs_but"> <Link className="btn btn--black" to="/track/next-day-delivery">Find out more</Link></div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="needs_box">
                                <div className="business_img" >
                                    <div className="business_needs" >
                                        <h3 className="block__title">Bullet Delivery</h3>
                                        <p></p>
                                        <p>Fast and Efficient: Our Bullet Delivery Service is designed to provide the fastest and most urgent delivery of your packages and documents. We understand the need for speed and prioritize the swift transportation of your items.</p>
                                        <p></p>
                                    </div>
                                    <div className="needs_but"> <Link className="btn btn--black" to="/track/bullet-delivery">Find out more</Link></div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2">&nbsp;</div>

                    </div>

                    <div className="track-hedding"><h1>NEED A LITTLE MORE HELP?</h1></div>

                    <div className="become_driver track-cont" style={{ background: "#ffffff", padding: "5%" }}>
                        <div className="inner-cont-trek " >
                            <h2>How do I Track my parcel?</h2><p />
                            <p>I’m expecting a delivery, how can I track its status?</p>
                            <p>You can <a href="https://www.timexpress.ae/track/">track your parcel</a> on our site. You’ll need your parcel number. It’s really easy!<br /> Just enter your details here: <a href="https://www.timexpress.ae/track/">Track</a> and off you go.</p><p /></div>
                        <div className="inner-cont-trek "><h2>Why is there no tracking against my parcel?</h2><p /><p>This could be down to a few things…</p>
                            <p>If you have just ordered your goods it may take a short time for the parcel information to update on our systems. If you have had your parcel number for more than a day and there is no tracking information, please contact the sender to verify the number is correct and that the parcel has been passed over to Time Express.</p><p /></div>
                        <div className="inner-cont-trek "><h2>There has been no tracking update for several days, what should I do?</h2><p /><p>If there has been no update for a couple of days and it has gone beyond the expected delivery date, please use the webchat facility and an advisor will be able to help you.</p><p /></div>
                        <div className="inner-cont-trek "><h2>Will I be notified of any planned delays to routes?</h2><p />
                            <p>We will notify you by email if we are aware of any bad weather or road closures that will affect your collections or deliveries. Please speak to your account manager however if you have any individual queries.</p><p />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

    function Ratepop(){
        return(
            <>
           <div className="container">
               <div className="row ratepop">
                   <h3>Rate your delivery experience</h3>
                   <h2>today</h2>
                   <p>How was the service you received for your shipment?</p>
                   <Rating onClick={handleRating} ratingValue={rating1.rate} />
                   <textarea className=" form-control" value={rating1.comment} onChange={(e)=>setRating({...rating1,comment:e.target.value})} rows="4" cols="20" id="" placeholder="your comment" / >
                   <div className="remind_later"> 
                   {/* <a href="javascript:void(0)" id="remindLater" className="remind">Remind me later</a>  */}
                   <a  id="getRating" className="send_btn">Send</a>
                   </div>
               </div>
           </div>
         
            </>
        )
    }
}

